footer {
  background-color: theme-color("orange");
  display: block;
  z-index: 2;
  position: relative;
  bottom: 0;
  margin-top: 7vw;
  min-height: 200px;
  padding-bottom: 3rem;

  &.angle {
    @include angle("before", true, false, 3deg);
  }

  .hornHolder {
    background-color: theme-color("orange");
    background-image: url("/images/horn.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 90% top;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .footerContent {
    padding-top: 3rem;
    display: inline-block;
    text-align: left;
    z-index: 1000;
    position: relative;

    h4 {
      color: theme-color("white");
      font-size: 1.5rem;
      font-weight: bold;
    }
    p {
      font-size: 1rem;
      color: theme-color("black");
    }

    img {
      margin-right: 0.5rem;

      &.icon {
        width: 48px;
        height: 48px;
      }
    }
  }

  .yellowLines {
    display: block;
    position: absolute;
    z-index: 10;
    background-repeat: no-repeat;
    overflow: hidden;
    pointer-events: none;

    &.left {
      background-image: url("/images/yellowlinessmall.svg");
      width: 25%;
      height: 200%;
      top: -100%;
      transform: scaleX(-1);
      background-position: 20% top;
      left: 0;
    }
  }

  .footerLogo {
    z-index: 1000;

    img {
      width:auto;
      height:35px;
      object-fit: contain;
      object-position: left;
      margin-bottom: 1rem;


        &.mw {
          @media all and (min-width: 768px) {
            width: 100%;
            height: auto;
          }
        }

      &.smallw {
        @media all and (min-width: 768px) {
          height: 30px;
        }
      }

    }
  }
}
