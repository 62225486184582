.partners {
  .headerImage {
    display: block;
   // max-height: 400px;
    min-height: 300px;
    background-color: theme-color("yellow");

    h1 {
      color: theme-color("cyan");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    p {
      color: theme-color("blue");
    }

    @media (max-width: 575.98px) {
      //max-height: 200px;
      min-height: 100px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: inherit;
      object-fit: cover;
    }
  }

  .angle {
    @include angle("before", true, false, 4deg);
    @include angle("after", true, false, -3deg);

    &::after {
      bottom: -8.1vw;
    }
  }





  .buttonSpace {
    padding-bottom: 100px;
  }

  .stepButton {
    border: 0;
    height: 60px;
    border-radius: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
  }
}

.partnerHeader {
  h1 {
    color: theme-color("blue");
    font-weight: bold;
    line-height: 0.95em;
    font-size: calc(6vw + 1.5vmin);

    @media (min-width: 576px) {
      font-size: calc(4vw + 1.5vmin);
    }
  }

  p {
    color: theme-color("blue");
  }

  .partnerBlock {
    .innerBlock {
      background-color: theme-color("keppel");
      height: 100%;

      .imageHolder {
        margin: 0;
        display: block;
        width: 100%;
        text-align: center;
        background-color: theme-color("spray");
        padding-top: 1em;
        padding-bottom: 1em;


        img {
          height: 3em;
          width: auto;
        }
      }

      .blockContent {

        h3 {
          color: theme-color("white");
          font-size: 1.4em;
          font-weight: bold;
        }

        p {
          padding-top: 2em;
          color: theme-color("white");
        }
      }
    }
  }

}