.tipsTricks {
  .headerImage {
    display: block;
    max-height: 400px;
    min-height: 300px;
    background-color: theme-color("spray");

    @media (max-width: 575.98px) {
      max-height: 200px;
      min-height: 100px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: inherit;
      object-fit: cover;
    }
  }

  .angle {
    @include angle("before", false, false, 4deg);
    @include angle("after", true, false, -3deg);

    &::after {
      bottom: -8.1vw;
    }
  }

  .tipsHeader {
    h1 {
      color: theme-color("blue");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    p {
      color: theme-color("blue");
    }

    .jobButton {
      border: 0;
      height: 60px;
      border-radius: 30px;
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 1.2rem;
      color: theme-color("orange");
      font-weight: bold;
    }

    .jobButtonLeft {
      border: 0;
      height: 60px;
      border-radius: 30px;
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 1.2rem;
      color: theme-color("white");
      background-color: theme-color("blue");
      font-weight: bold;
    }

    .jobButtonRight {
      border: 0;
      height: 60px;
      border-radius: 30px;
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 1.2rem;
      color: theme-color("white");
      background-color: theme-color("blue");
      font-weight: bold;
    }

    .tip {

      h3 {
        font-size: 3em;
        font-weight: bold;
      }

      p {
        color: theme-color("black");
        font-size: 1.2em;
      }

      button {
        border: 0;
        height: 60px;
        border-radius: 30px;
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.2rem;
        bottom: 0;
        font-weight: bold;
      }

      &.tip0 {
        color: theme-color("orange");

        button {
          color: theme-color("white");
          background-color: theme-color("orange");
        }
      }

      &.tip1 {
        color: theme-color("blue");

        button {
          color: theme-color("white");
          background-color: theme-color("blue");
        }
      }

      &.tip2 {
        color: theme-color("cyan");

        button {
          color: theme-color("white");
          background-color: theme-color("cyan");
        }
      }

      &.tip3 {
        color: theme-color("purple");

        button {
          color: theme-color("white");
          background-color: theme-color("purple");
        }
      }
    }
  }

  .learnPathContainer {
    z-index: 5;
    position: relative;

    /*div[class*='col-'] {
      display: flex;
    }*/

    .bg-block-0 {
      h3 {
        color: theme-color("white");
        background-color: theme-color("orange");
      }

      .container {
        background-color: theme-color("purple");
      }

      .stepButton {
        color: theme-color("blue");
        background-color: theme-color("yellow");
      }
    }

    .bg-block-1 {
      h3 {
        color: theme-color("blue");
        background-color: theme-color("lightblue");
      }

      .container {
        background-color: theme-color("cyan");
      }

      .stepButton {
        color: theme-color("white");
        background-color: theme-color("orange");
      }
    }

    .bg-block-2 {
      h3 {
        color: theme-color("blue");
        background-color: theme-color("yellow");
      }

      .container {
        background-color: theme-color("orange");
      }

      .stepButton {
        color: theme-color("blue");
        background-color: theme-color("lightblue");
      }
    }

    .bg-block-3 {
      h3 {
        color: theme-color("white");
        background-color: theme-color("purple");
      }

      .container {
        background-color: theme-color("lightblue");
      }

      .stepButton {
        color: theme-color("blue");
        background-color: theme-color("yellow");
      }
    }

    .learnPathBlock {
      width: 100%;

      h3 {
        font-weight: bold;
        font-size: 2rem;
        padding: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
        position: relative;
        z-index: 2;
        display: inline-block;

        @media (min-width: 576px) {
          font-size: 2.5rem;
        }
      }

      h5 {
        color: theme-color("white");
        font-weight: normal;
        font-size: 1.2em;
        display: block;
        position: relative;

        strong,
        b {
          color: theme-color("blue");
        }
      }
      .container {
        margin-top: -3.5em;
        padding-top: 3em;
      }

      span,
      p {
        font-weight: normal;
        font-size: 1rem;
        color: theme-color("white");

        ul {
          padding: 1rem;
        }
      }
    }
  }

  .buttonSpace {
    padding-bottom: 100px;
  }

  .stepButton {
    border: 0;
    height: 60px;
    border-radius: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
  }
}
