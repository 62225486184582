body {
  font-family: $font-family;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}

.bg-purple {
  background-color: $purple;

  h2 {
    color: #fff;
    font-weight: bold;
    font-size: calc(4vw + 1vmin);

    @media (min-width: 576px) {
      font-size: calc(3vw + 1vmin);
    }
  }

  span {
    color: $yellow;
    font-weight: bold;

    font-size: calc(2vw + 1vmin);

    @media (min-width: 576px) {
      font-size: calc(1.5vw + 1vmin);
    }
  }
}

.top-navbar-container {
  background-color: theme-color("white");

  .logo {
    position: absolute;
    top: 0;

    img {
      max-width: 250px;
      width: 14vw;
      min-width: 140px;
    }
  }

  a.nav-link {
    @extend .text-blue;

    //font-size: 20px;
    text-transform: uppercase;
    transition: color 0.3s;

  }

  .user-login {
    a{
      &.nav-link {
        //padding-top: 0.6em;
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 992px) {
    a.nav-link {
      white-space: nowrap;

      &.inverted {
        color: $white !important;
        background-color: theme-color("orange");
        border-radius: 2em;

        &:hover {
          color: theme-color("blue") !important;
        }
      }
    }
  }
}

@each $name, $color in $theme-colors {
  .bg-#{$name} {
    &.rotate-row {
      @include angle("before", true, false, 6deg);
    }
  }
}

.depth1{
  .dropdown-menu {
    top: calc(100% - 51px);
    left: 100%;
  }
}

.dropdown-menu {
  margin: 0px;
  border: none;
  border-radius: 0;
  left: unset;
  right: unset;
  min-width: 15rem;
  //margin-top: 8px !important;
  border-bottom: 1px solid #aaa;
  padding:0;

  a,
  a:hover,
  a:active,
  a:visited {
    color: theme-color("blue");
  }

  a:active {
    background-color: #aaa;//darken(#f8f9fa, 10);
  }

  .dropdown-item {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #aaa;
    width: auto;
    text-transform: uppercase;
    white-space: normal;
    a {
      font-size: 0.8em;
    }
    &:active,
    &.active {
      background-color: #ddd;
    }
  }
}

.big-spinner {
  width: 3rem;
  height: 3rem;
}

.small-spinner {
  width: 1rem;
  height: 1rem;
  border-width: 0.125rem;
}

.inner-text-black * {
  color: black !important;
}

.cookie-consent-container {
  z-index: 5;
  bottom: 0;

  .cookie-consent {
    bottom: 0;
    z-index: 5;
  }
}

.jobBlock {
  background-color: theme-color("yellow");

  h3 {
    color: theme-color("blue");
    font-weight: bold;
    font-size: 2rem;

    @media (min-width: 576px) {
      font-size: 2.5rem;
    }
  }

  span,
  p {
    color: theme-color("blue") !important;
    font-weight: normal;
    font-size: 1rem !important;

    ul {
      padding: 1rem;
    }
  }

  @media (min-width:768px) {
    .blockHeader {
      height: 220px;

      h2 {
        line-height: 1.2em;
        height: 2.4em;
        overflow: hidden;
      }
    }

    .blockContent {
      p {
        line-height: 1.5em;
        height: 4.5em;
        width: 100%;
        overflow: hidden;
        //margin-right: -1em;
        //padding-right: 1em;
        position: relative;
        display: inline-block;
        overflow: hidden;

        /*&:before {
          content: '...';
          position: absolute;
          left: 0;
          bottom: -1.5em;
          color: theme-color('blue');
        }

        &:after {
          content: '';
          position: absolute;
          right: -0.2em;
          width: 1em;
          height: 1em;
          margin-top: 0.75em;
          background: white;
        }*/
      }

      .btn {
        font-size: 1em;
      }

    }
  }

}

.custom-button-container {
  button {
    border: 0;
    width: 100%;
    min-height: 120px;
    font-size: 2.2rem;
    font-weight: bold;

    &.button-0 {
      color: theme-color("blue");
      background-color: theme-color("spray");
    }

    &.button-1 {
      color: theme-color("blue");
      background-color: theme-color-level("spray", -3);
    }

    &.button-2 {
      color: theme-color("white");
      background-color: theme-color-level("cyan", -3);
    }

    &.button-3 {
      color: theme-color("white");
      background-color: theme-color("cyan");
    }

    &.button-4 {
      color: theme-color("blue");
      background-color: theme-color("yellow");
    }

    &.button-5 {
      color: theme-color("blue");
      background-color: theme-color-level("yellow", -3);
    }

    &.button-6 {
      color: theme-color("blue");
      background-color: theme-color-level("orange", -3);
    }

    &.button-7 {
      color: theme-color("white");
      background-color: theme-color("orange");
    }

    &.button-8 {
      color: theme-color("white");
      background-color: theme-color("purple");
    }

    &.button-9 {
      color: theme-color("white");
      background-color: theme-color-level("purple", -3);
    }
  }
}

.btn-rounded {
  @extend .btn-primary;

  border: 0;
  border-radius: 30px;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

div.modal-header.flex-column {
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.modal-content {
  @extend .border-0;
}

.h2-smaller {
  font-size: 1.5em !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ============ desktop view ============ */

a.dropdown-toggle{
  &:after {
    border:0;
    background-image: url('/images/menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    width:15px;
    height:15px;
    vertical-align: unset;
  }
}

.depth1 {
  a.dropdown-toggle{
    margin-right: 2em;
    &:after {
      border:0;
      background-image: url('/images/menu_right.svg');
      background-position: center;
      background-repeat: no-repeat;
      width:15px;
      height:15px;
      vertical-align: unset;
      position: absolute;
      right:1em;
    }
  }
}

@media all and (max-width: 992px) {

  label {
    &.dropdown-toggle {
      &:after {
        border: 0;
        background-image: url('/images/menu.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        vertical-align: unset;
      }
    }
  }
  .navbar {
    overflow: auto;
    max-height: 100vh;
  }

  a.dropdown-toggle{
    &:after {
      display: none;
    }
  }
  .mobile-toggle {
    display: none;
  }

  .dropdown {
    .mobile-toggle {
      display: inline-block;
      position: absolute;
      right: 1em;
      top: 0.5em;
      width:2em;
      height: 2em;
      cursor: pointer;
      color: theme-color("blue");
    }

    input:checked + .dropdown-menu { display: block; }
    input:checked + .dropdown-menu ~ .dropdown-menu { display: none; }
  }
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu{ display: none; }
  //.navbar .nav-item:hover .nav-link{ color: #fff;  }
  //.navbar .nav-item:hover .dropdown-menu{ display: block; }
  .navbar .nav-item .dropdown-menu{ margin-top:0; }

  .navbar .dropdown:hover > .dropdown-menu { display: block; }
  .navbar .dropdown:hover > .dropdown-menu ~ .dropdown-menu { display: none; }
}
/* ============ desktop view .end// ============ */

.userIcon {
  width: 20px;
  height: auto;
}

.z-top {
  position: relative;
  z-index: 100;
}

.form-holder {

  form {
    margin-top: 3em;
  }

  .formError {
    display: block;
    font-size: 1rem;
    color: theme-color("yellow");
  }

  .rightInfo {
    text-align: right;

    position: relative;
    span {
      color: theme-color("white");
      font-size: 1em;
      padding-right: 1em;
    }
    a {
      color: theme-color("white");
      font-size: 1em;
    }
  }

  button {
    border:0;
    border-radius: 0;
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
  }

  input[type=text],
  input[type=email]{
    border: 0;
    border-radius: 0;
    height: 3rem;
    margin-top: -1em;
  }

  input[type=checkbox] {
    border: 0;
    border-radius: 0;
    height: 1.5rem;
    width: 1.5em;
    display: inline-block;
  }

  span {
    &.checkLabel {
      padding-left: 1em;
      font-size: 1em;
      color: theme-color("white");
      font-weight: normal;
      vertical-align: super;
    }
  }
  label {
    display: inline-block;
    background-color: theme-color("blue");
    color: theme-color("white");
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.profile-holder {
  h4 {
    display: inline-block;
    background-color: theme-color('blue');
    color: theme-color('white');
    font-size: 1.2rem;
    padding: 0.4em;
    padding-left: 0.7em;
    padding-right: 0.7em;
  }

  p {
    color: theme-color('white') !important;
  }
}

.main_button {
  border: 0;
  height: 45px;
  border-radius: 25px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}

input[type=checkbox] {
  border: 0;
  border-radius: 0;
  height: 1.5rem;
  width: 1.5em;
  display: inline-block;
}

.profile-label {
  vertical-align: middle;
  display: block;

  label {
    padding:0;
    margin:0;
    margin-left: 1em;
    display: inline-block;
    vertical-align: super;
  }
}

.form-group {
  .checkLabel{
    display: inline;
    padding-left: 1em;
    vertical-align: super;
  }
}

.text-smaller {
  font-size: 1rem !important;
}

.white-p {
  p {
    color: theme-color("white") !important;
  }
}

.link-white {
  a {
    color: theme-color("white");
    text-decoration: underline;
  }
}

.titleImage {

    height: calc(7vw + 1.5vmin);
    @media (min-width: 576px) {
      height: calc(5vw + 1.5vmin);
    }
    margin-left: 0.2em;

}

.navbar-light {
  .navbar-toggler {
    border: none;
    .navbar-toggler-icon {
      background-image: url("/images/hamburger.svg");

    }
  }
}

.modal-header {
  .close {
    color: theme-color("white");
    text-shadow: none;
    opacity: 1;
    font-size: 0;
    outline: none;

    &:after {
      position: relative;
      display: block;
      content: "";
      width: 25px;
      height: 25px;
      background-size: contain;
      background-image: url("/images/close.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}