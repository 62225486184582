.joboverview {
  .headerImage {
    display: block;
    //max-height: 400px;
    min-height: 300px;
    background-color: theme-color("yellow");

    h1 {
      color: theme-color("blue");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    p {
      color: theme-color("black");
    }

    @media (max-width: 575.98px) {
      //max-height: 200px;
      min-height: 100px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: inherit;
      object-fit: cover;
    }
  }

  .angle {
    @include angle("before", true, false, 4deg);
    @include angle("after", true, false, -3deg);

    &::after {
      bottom: -8.1vw;
    }
  }

  .partnerHeader {
    h1 {
      color: theme-color("blue");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    p {
      color: theme-color("blue");
    }

    .partnerBlock {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      height: 100%;

      &:after {
        display: flex;
        content: '';
        background-color: theme-color("yellow");
        height: 1em;
      }

      background-color: theme-color("white");

      .imageHolder {
        margin: 0;
        display: block;
        width:100%;
        text-align: left;
        background-color: theme-color("spray");

        h3 {
          color: theme-color("blue");
          font-size: 1.4em;
          font-weight: bold;
          padding:0;
          margin: 0;
        }

        &.bg0 {
          background-color: theme-color("cyan");
        }

        &.bg1 {
          background-color: theme-color("spray");
        }

        &.bg2 {
          background-color: theme-color("purple");
        }

        &.bg3 {
          background-color: theme-color("lila");
        }
      }

      .blockContent {

        flex-grow: 1;

        h3 {
          color: theme-color("white");
          font-size: 1.4em;
          font-weight: bold;
        }

        p {
          color: theme-color("blue");
        }
      }

      .buttonHolder {
        text-align: center;

        button {
          border: 0;
          height: 60px;
          border-radius: 30px;
          padding-left: 3rem;
          padding-right: 3rem;
          font-size: 1.2rem;
          color: theme-color("white");
          bottom: 0;
          font-weight: bold;

          &.bg-blue {
            margin-top: 1em;
          }
        }
      }
    }

  }



  .buttonSpace {
    padding-bottom: 100px;
  }

  .stepButton {
    border: 0;
    height: 60px;
    border-radius: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
  }
}
