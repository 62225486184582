.job {
  .headerImage {
    display: block;
    max-height: 325px;

    @media (max-width: 575.98px) {
      max-height: 100px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: inherit;
      object-fit: cover;

      &.rightImage {
        width: 6rem;
        height: auto;
        top: 2rem;
        position: absolute;
        right: 2rem;

        @media (max-width: 575.98px) {
          width: 4rem;
          top:1rem;
          right: 1rem;
        }
      }
    }
  }

  .angle {
    @include angle("before", false, false, 4deg);
    @include angle("after", false, false, -4deg);

    &::after {
      bottom: -14.1vw;
      border-bottom: 1rem solid theme-color-level("lightblue", 3);
    }
  }

  .jobHeader {
    h1 {
      color: theme-color("white");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }


    }

    img {
      height: calc(7vw + 1.5vmin);
      @media (min-width: 576px) {
        height: calc(5vw + 1.5vmin);
      }
      margin-left: 0.2em;
    }

    p {
      color: theme-color("white");
      font-size: 1.5rem;
    }
  }

  .jobContainer {
    div[class*="col-"] {
      display: flex;
    }

    .jobBlock {
      background-color: theme-color("yellow");

      h3 {
        color: theme-color("blue");
        font-weight: bold;
        font-size: 2rem;

        @media (min-width: 576px) {
          font-size: 2.5rem;
        }
      }

      span,
      p {
        color: theme-color("blue");
        font-weight: normal;
        font-size: 1rem;

        ul {
          padding: 1rem;
        }

        &.text-white {
          color: theme-color("white") !important;
          p {
            color: theme-color("white") !important;
          }
        }
      }
    }
  }

  .skillBlock {
    h3 {
      font-weight: bold;
      font-size: 1.5rem;
      padding: 0.5em;
      padding-left: 1em;
      padding-right: 1em;
      position: relative;
      z-index: 2;
      display: inline-block;

      @media (min-width: 576px) {
        font-size: 1.7rem;
      }
      color: theme-color("blue");
      background-color: theme-color("lightblue");
    }

    .content {
      margin-top: -3em;
      padding-top: 3em;
      width: 100%;
      height: 100%;
      background-color: theme-color("cyan");
      font-size: 1em;
      li{
        font-size: 1rem;
      }
    }
  }
  .jobButton {
    border: 0;
    height: 90px;
    border-radius: 45px;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1.7rem;
    color: theme-color("orange");
    font-weight: bold;
  }

  .jobButtonLeft {
    border: 0;
    height: 90px;
    border-radius: 45px;
    font-size: 1.7rem;
    color: theme-color("white");
    background-color: theme-color("blue");
    font-weight: bold;
  }

  .jobButtonRight {
    border: 0;
    height: 90px;
    border-radius: 45px;
    font-size: 1.7rem;
    color: theme-color("white");
    background-color: theme-color("blue");
    font-weight: bold;
  }
}
