@import "scss/variables";
@import "~bootstrap/scss/bootstrap";
@import "scss/mixins/angle";
@import "scss/fonts";
@import "scss/main";
@import "scss/components/footer";
@import "scss/components/customForm";
@import "scss/components/testmodal";

@import "scss/pages/homePage";
@import "scss/pages/jobPage";
@import "scss/pages/learnPathPage";
@import "scss/pages/tipstricks";
@import "scss/pages/partners";
@import "scss/pages/page";
@import "scss/pages/joboverview";
@import "scss/pages/landingsPage";

