@font-face {
  font-family: "MarkPro";
  src:
          url("/fonts/MarkPro.eot") format("embedded-opentype"),
          url("/fonts/MarkPro.woff2") format("woff2"),
          url("/fonts/MarkPro.woff") format("woff"),
          url("/fonts/MarkPro.ttf") format("truetype"),
          url("/fonts/MarkPro.svg#MarkPro") format("svg");
  font-weight: normal;
}

@font-face {
  font-family: "MarkPro";
  src:
          url("/fonts/MarkProBlack.eot") format("embedded-opentype"),
          url("/fonts/MarkProBlack.woff2") format("woff2"),
          url("/fonts/MarkProBlack.woff") format("woff"),
          url("/fonts/MarkProBlack.ttf") format("truetype"),
          url("/fonts/MarkProBlack.svg#MarkProBlack") format("svg");
  font-weight: bold;
}