.landingsPage {
  .headerImage {
    display: block;
    max-height: 400px;
    min-height: 300px;
    background-color: theme-color("purple");

    @media (max-width: 575.98px) {
      max-height: 200px;
      min-height: 100px;
    }

    img {
      width: 100%;
      height: auto;
      max-height: inherit;
      object-fit: cover;
    }
  }

  .angle {
    @include angle("before", false, false, 4deg);


    &::after {
      bottom: -8.1vw;
    }
  }

  .learnPathHeader {
    h1 {
      color: theme-color("white");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(6vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    * {
      color: theme-color("blue");
      font-size: 1.5rem;
    }
  }

  .learnPathContainer {
    z-index: 5;
    position: relative;

    /*div[class*='col-'] {
      display: flex;
    }*/

    h2 {
      color: theme-color("blue");
      font-weight: bold;
    }

    h3 {
      color: theme-color("blue");
      font-weight: bold;
    }


    .blockButton {
      border: 0;
      height: 60px;
      border-radius: 30px;
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 1.2rem;
      background-color: theme-color("blue");
      color: theme-color("white");
      bottom: 0;
      font-weight: bold;
    }

    span,
    p {
      font-weight: normal;
      font-size: 1rem;
      color: theme-color("blue");

      ul {
        padding: 1rem;
      }
    }
  }

  .titleContainer {
    padding-top: 3em;
  }

  .infoBlock {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;


    .contentHolder {
      flex-grow: 1;
    }
  }
  .bg0 {

    background-color: theme-color("yellow");
    @include angle("after", false, false, 3deg);
    padding-top: 3em;

    &.bgangle0 {
      @include angle("before", false, false, 3deg);
      padding-top: 0;
    }

    .infoBlock {
      background-color: theme-color("white");

      &:after {
        display: flex;
        content: '';
        background-color: theme-color("yellow");
        height: 1em;
      }

      .titleHolder {
        background-color: theme-color("purple");
        h4 {
          font-weight: bold;
          color: theme-color("white");
          margin: 0;
        }
      }
    }
  }

  .bg1 {
    margin-top: 7em;
    background-color: theme-color("white");

    .infoBlock {
      background-color: theme-color("purple");

      &:after {
        display: flex;
        content: '';
        background-color: theme-color("white");
        height: 1em;
      }

      .titleHolder {
        background-color: theme-color("orange");
        h4 {
          font-weight: bold;
          color: theme-color("white");
          margin: 0;
        }
      }

      .contentHolder {
        p {
          color: theme-color("white");
        }
      }
    }
  }

  .bg2 {

    h3 {
      color: theme-color("white");
      font-weight: bold;
    }

    p {
      color: theme-color("white");
    }

    background-color: theme-color("cyan");
    @include angle("before", false, false, 3deg);


    .infoBlock {
      background-color: theme-color("yellow");

      .titleHolder {
        background-color: theme-color("lightblue");
        h4 {
          font-weight: bold;
          color: theme-color("white");
          margin: 0;
        }
      }

      .contentHolder {
        p {
          color: theme-color("blue");
        }
      }
    }
  }

  .buttonSpace {
    padding-bottom: 100px;
  }

  .stepButton {
    border: 0;
    height: 60px;
    border-radius: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
    font-weight: bold;
  }

  .jobButton {
    border: 0;
    height: 90px;
    border-radius: 45px;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1.7rem;
    color: theme-color("orange");
    font-weight: bold;
  }

  .jobButtonLeft {
    border: 0;
    height: 90px;
    border-radius: 45px;
    font-size: 1.7rem;
    color: theme-color("white");
    background-color: theme-color("blue");
    font-weight: bold;
  }

  .jobButtonRight {
    border: 0;
    height: 90px;
    border-radius: 45px;
    font-size: 1.7rem;
    color: theme-color("white");
    background-color: theme-color("blue");
    font-weight: bold;
  }
}
