.home {
  .bg-pink {
    padding-bottom: 5em;

    h3 {
      color: $blue;
      font-weight: bold;
      font-size: calc(4vw + 1vmin);

      @media (min-width: 576px) {
        font-size: calc(3vw + 1vmin);
      }
    }

    h1 {
      color: $orange;
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(10vw + 1.5vmin);

      &.smaller {
        font-size: calc(8vw + 1.25vmin);
      }

      @media (min-width: 576px) {
        font-size: calc(6vw + 1.5vmin);

        &.smaller {
          font-size: calc(4vw + 1.25vmin);
        }
      }
    }

    img {
      width: 60%;
      height: auto;
      @media (min-width: 576px) {
        width: 100%;
      }
    }
  }

  .secondHolder {
    @include angle("before", true, false, 3deg);

    .textHolder {
      color: theme-color("white");
      font-size: 1.5rem;

      strong,
      b {
        font-weight: bold;
        color: theme-color("yellow");
      }
    }

    & > img {
      bottom: 0;
      max-height: 600px;
    }
  }

  .thirdHolder {
    @include angle("after", true, false, 3deg);

    &::after {
      border-bottom: 0.5em solid theme-color-level("yellow", 3);
    }

    h2 {
      color: theme-color("blue");
      font-weight: bold;
      line-height: 0.95em;
      font-size: calc(7vw + 1.5vmin);

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }

      strong,
      b {
        color: theme-color("cyan");
      }
    }

    .image-container {
      img {
        max-height: 85px;
      }
    }
  }

  .fourthHolder {
    h3 {
      color: theme-color("yellow");
      font-size: calc(8vw + 1.5vmin);
      font-weight: bold;

      @media (min-width: 576px) {
        font-size: calc(6vw + 1.5vmin);
      }
    }

    h2 {
      color: theme-color("orange");
      font-size: calc(6vw + 1.5vmin);
      font-weight: bold;
      line-height: 0.9em;

      @media (min-width: 576px) {
        font-size: calc(4vw + 1.5vmin);
      }
    }

    p {
      margin-top: 2em;
      color: theme-color("blue");
      font-size: 1.5rem;
    }

    & > img {
      bottom: 0;
      right: 0;
      max-height: 600px;
    }
  }

  .jobButton {
    background-color: theme-color("orange");
    border: 0;
    height: 65px;
    border-radius: 45px;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1.7rem;
    color: $white;

    p {
      margin-bottom: 0;
    }

    span {
      color: theme-color("blue");
    }
  }
}
