.test-modal {
  .modal-dialog {

    @media (min-width: 576px) {
      max-width: 95vw;
    }

    @media (min-width: 1100px) {
      max-width: 60vw;
    }

    @media (min-width: 1300px) {
      max-width: 50vw;
    }

    .modal-content {
      background: unset;

      .modal-header {
        border: 0;

        color: theme-color("white");

        h3 {
          width: auto;
          position: relative;
          line-height: 1em;
        }
      }

      .modal-body {
        background-color: theme-color("pink");
      }
    }
  }
}

body
{
  &.modal-open {
    #root {
      filter: blur(5px);
    }
  }
}
