$theme-colors: (
  "pink": #fae2fc,
  "purple": #7d77ff,
  "yellow": #f5ff77,
  "blue": #041db7,
  "orange": #f56d29,
  "white": #fff,
  "black": #000,
  "cyan": #36b8a8,
  "lightblue": #a5e1f3,
  "lila": #e0d9fb,
  "spray": #7fd5ee,
  "keppel": #36b8a8,
  "daisy": #f5ff77,
  "flamingo": #f56d29,
  "cornflower-blue": #7d77ff,
);

$font-family: "MarkPro";
