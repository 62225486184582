.customForm {

  .titleHolder {
    background-color: theme-color("cyan");
    h3 {
      font-weight: bold;
      color: theme-color("blue");
    }
  }

  textarea,
  input[type='text'],
  input[type='email'] {
    color: theme-color("blue");
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border: 1px solid theme-color("cyan");
    height: 45px;
    border-radius: 20px;
    outline: none;
    padding-left: 2em;

    &::placeholder {
      color: theme-color("cyan");
    }
  }

  textarea {
    height: 6em;
  }

  .customFormField {
    label {
      color: theme-color("blue");
      cursor: pointer;
    }

    label:before {
      width: 20px;
      height: 20px;
      position: relative;
      top: 4px;
      margin-right: 1em;
      display: inline-block;
      border: 1px solid theme-color("cyan");
      border-radius: 50%;
      content: "";
    }

    input[type="checkbox"] {
      visibility: hidden;
      width: 0;
    }

    input[type="checkbox"]:checked + label:before {
      background-color: theme-color("blue");
    }

    input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }

  .submitButton {
    background-color: theme-color("orange");
    color: theme-color("white");
    font-weight: bold;
    font-size: 1.5em;
    width: 100%;
    height: 90px;
    border: none;
    border-radius: 45px;
  }
}